import React from "react";
import { StaticQuery, graphql } from "gatsby";

import SEO from "components/seo";
import Layout from "components/layout";

export default () => (
    <StaticQuery
        query={graphql`
            query {
                dataJson(teams: {intro: {ne: null}}) {
                    teams {
                        intro
                        list {
                            image {
                                publicURL
                            }
                            title
                        }
                    }
                }
            }
        `}
        render={data => (
            <Layout>
                <SEO
                    pageData={{
                        title: `Teams that play at the Sydney Football Stadium`,
                        description: `Many sports teams play at the Sydney Football Stadium including Sydney FC, Sydney Roosters and NSW Waratahs.`
                    }}
                />
                <div className="container-fluid">
                    <h1>Teams that play at the SFS</h1>

                    <div className="md:mx-auto mb-12 md:w-3/5" dangerouslySetInnerHTML={{ __html: data.dataJson.teams.intro }} />

                    <div className="md:flex md:flex-wrap md:-mx-3">
                        {data.dataJson.teams.list.map((edge,index) => {
                            return (
                                <div className="mb-12 md:px-3 md:w-1/3 text-center" key={index}>
                                    <img className="mx-auto mb-3" style={{height: `163px`}} src={edge.image.publicURL} alt={edge.title } />
                                    <h2 className="" dangerouslySetInnerHTML={{ __html: edge.title }} />
                                </div>
                            )
                            })}
                    </div>
                    
                </div>
            </Layout>
        )}
    />
)
